import React from "react"

import ThemeContext from "../context/ThemeContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Process from "../components/sections/process"
import Projects from "../components/sections/projects"
import Footer from "../components/footer"

import loadable from "@loadable/component"
const RequestNonSSR = loadable(() => import("../components/sections/request"))

const IndexPage = ({ location }) => (
    <Layout>
        <SEO title="Rafael Heard - Front End Engineer" />
        <ThemeContext.Consumer>
            {theme => (
                <>
                    <Hero theme={theme} location={location} />
                    <Process />
                    <Projects theme={theme} />
                    <RequestNonSSR theme={theme} />
                    <Footer theme={theme} />
                </>
            )}
        </ThemeContext.Consumer>
    </Layout>
)

export default IndexPage
