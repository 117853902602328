import React from "react"
import styled from "styled-components"

import { HeaderTwo, HeaderThree } from "../header-text"
import {
    Blueprint,
    HardHat,
    Rocket,
    Curve,
    ThreeComets,
    TwoComets,
    OneComet,
    CurvedConnect,
    StraightLine,
} from "../svg/index"

const SCGradient = styled.div`
    background: linear-gradient(
        var(--theme-white),
        var(--theme-bb),
        var(--theme-coral)
    );
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0 calc(-50vw + 50%);
    opacity: 0.2;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
`

const SCProcessContainer = styled.section`
    margin: auto;
    max-width: 1200px;
    padding: 100px var(--container-padding) 0;
    position: relative;

    h2 {
        margin-bottom: 80px;
        text-align: center;
    }
`

const SCProcessGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, auto);
    row-gap: 50px;
    margin-bottom: 80px;

    @media (min-width: 800px) {
        grid-template-columns: 1fr 1fr;
    }

    .c-d-curve {
        grid-row: 2;
        grid-column: 1;
        margin: auto;

        @media (min-width: 800px) {
            grid-row: 1;
            left: 100%;
            margin: 0;
            top: 57px;
            transform: rotate(67deg);
        }
    }

    .d-l-curve {
        grid-column: 1;
        grid-row: 4;
        margin: auto;

        @media (min-width: 800px) {
            grid-column: 1;
            grid-row: 3;
            left: 90%;
            top: -33px;
            right: 52px;
            margin-right: auto;
            margin-left: 0;
            transform: rotate(182deg);
        }
    }
`

const SCProcess = styled.div`
    display: grid;
    grid-column: ${props => props.column[0]};
    grid-row: ${props => props.row[0]};
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    max-width: 450px;
    position: relative;
    width: 100%;

    @media (min-width: 800px) {
        grid-column: ${props => props.column[1]};
        grid-row: ${props => props.row[1]};
        max-width: 375px;
    }

    h3 {
        text-align: center;
        grid-column: 1/4;
    }

    p {
        color: var(--theme-blue);
        opacity: 0.8;
        font-size: 1.8rem;
        grid-column: 1/3;
        margin-bottom: 0;
        margin-right: 20px;
    }

    svg {
        fill: var(--theme-coral);
        align-self: center;
    }
`

const Process = () => {
    return (
        <SCProcessContainer>
            <SCGradient />
            <HeaderTwo text="A Fluid Process" />
            <SCProcessGrid>
                <CurvedConnect classes="c-d-curve" width="200" />
                <CurvedConnect classes="d-l-curve" width="200" />
                <StraightLine classes="c-d-curve" width="100" />
                <StraightLine classes="d-l-curve" width="100" />
                <SCProcess column={["1/3", 1]} row={[1, 1]}>
                    <HeaderThree text="Create" />
                    <p>
                        Quality time is spent with my clients to transform their
                        ideas and opinions into well thought out designs. You
                        only get one first impression.
                    </p>
                    <Blueprint width="83" />
                </SCProcess>
                <SCProcess column={["1/3", 2]} row={[3, 2]}>
                    <HeaderThree text="Develop" />
                    <p>
                        Initial designs are transformed into working
                        applications that provide an effortless user experience
                        encompassed by beautiful user interfaces.
                    </p>
                    <HardHat width="83" />
                </SCProcess>
                <SCProcess column={[1, 1]} row={[5, 3]}>
                    <HeaderThree text="Launch" />
                    <p>
                        Your application is launched for public use. If any
                        additional features are needed, we can revisit the
                        application at any time in the future.
                    </p>
                    <Rocket width="110" animate={true} />
                </SCProcess>
            </SCProcessGrid>
            <ThreeComets width="700" position={{ right: -200, top: 100 }} />
            <TwoComets width="400" position={{ left: 200, top: 50 }} />
            <OneComet width="200" position={{ right: `100%`, bottom: 350 }} />
            <Curve />
        </SCProcessContainer>
    )
}

export default Process
