import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { HeaderTwo, HeaderFour } from "../header-text"
import Button from "../button"
import {
    ThreeAsteroids,
    ThreeComets,
    Meteor,
    MeteorTwo,
    YujoLogo,
    KTLogo,
    SingleAsteriod,
    TwoAsteroids,
} from "../svg/index"

const SCPrjectsContainer = styled.div`
    padding: 100px var(--container-padding) 130px;
    position: relative;

    h2 {
        margin-bottom: 80px;
        text-align: center;
    }
`

const SCProjectGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-column-gap: 30px;
    justify-content: space-evenly;
    margin: auto;
    max-width: 800px;
    width: 100%;
`

const SCProject = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 10px 30px 100px;
    position: relative;
    text-align: center;

    @media (min-width: 800px) {
        flex-direction: column;
    }

    .meteor-svg {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
    }

    button {
        min-width: 120px;
        position: relative;
        z-index: 1;
    }
`

const Projects = ({ theme }) => {
    const data = useStaticQuery(graphql`
        query {
            allDataJson {
                nodes {
                    description
                    id
                    title
                }
            }
        }
    `)

    return (
        <SCPrjectsContainer>
            <HeaderTwo text="Project Launches" />
            <SCProjectGrid>
                <SCProject>
                    <YujoLogo sizes={[100, 81]} />
                    <div>
                        <HeaderFour text={data.allDataJson.nodes[0].title} />
                        <Button
                            text="View"
                            attributes={{
                                "data-micromodal-trigger": "project-modal",
                            }}
                            event={() =>
                                theme.updateProjectId(
                                    data.allDataJson.nodes[0].id
                                )
                            }
                        />
                    </div>
                    <Meteor />
                </SCProject>
                <SCProject>
                    <KTLogo sizes={[87, 81]} />
                    <div>
                        <HeaderFour text={data.allDataJson.nodes[1].title} />
                        <Button
                            text="View"
                            attributes={{
                                "data-micromodal-trigger": "project-modal",
                            }}
                            event={() =>
                                theme.updateProjectId(
                                    data.allDataJson.nodes[1].id
                                )
                            }
                        />
                    </div>
                    <MeteorTwo />
                </SCProject>
            </SCProjectGrid>
            <ThreeComets
                width="450"
                rotate="106"
                position={{ left: -300, top: -100 }}
            />
            <ThreeAsteroids width={200} position={{ right: 0, top: 0 }} />
            <SingleAsteriod width={42} position={{ left: `25%`, top: 10 }} />
            <TwoAsteroids width={200} position={{ right: `20%`, bottom: 10 }} />
        </SCPrjectsContainer>
    )
}

export default Projects
